/* eslint-disable */
import { prepareSubmit } from '../../../../../vendor/inbox/toolbarbundle/assets/js/utils/sha1';
import biscuit from '../../../../../vendor/inbox/toolbarbundle/assets/js/utils/cookie-handler';
import inx from "inx";
import csrf_protection from "./csrf_protection";

inx.portal = (() => {
    const C = {
            IDS: {
                ARTICLE:  '#inx-article',
                ASIDE:    '#inx-aside-products',
                ERRLIST:  '#messages-list',
                ERRMES:   '#ii-state-messages',
                LFORM:    '#implogin',
                PASS:     '#pass',
                USER:     '#imapuser',
                // USESSL:   '#mlusessl'
            },
            CN: {
                PROD:     '.inx-products',
                ROW3:     '.row-3',
                LOGIN_BOTTOM_BLOCK: '.login-bottom-block'
            },
            last_focus_field: null
        };

    function canSetCookie() {
        const cookie = { name: 'ctest', value: (new Date()).getTime() };
        biscuit.setIt(cookie.name, cookie.value);
        return biscuit.getIt(cookie.name) ? true : false;
    }

    return {
        // getLastFocusField: function () {
        //     return C.last_focus_field;
        // },
        setMailHandler: function (url, SITE) {
            if (navigator.registerProtocolHandler) {
                try {
                    navigator.registerProtocolHandler("mailto", url , SITE + " mail");
                } catch (e) {
                    console.warn(e);
                }
            }
        },
        refocus_login_form: function () {
            // Set focus for Username (Ticket: PORTAL-667)
            var field = C.last_focus_field;
            if (field) {
                //Trigger autocomplete off/on. Workaround for firefox autocomplete issue in Ticket PORTAL-667
                field.setAttribute( "autocomplete", "off" );
                field.focus();
                field.setAttribute( "autocomplete", "on" );
            } else {
                const userEl =  document.querySelector(C.IDS.USER);
                if (userEl) {
                    userEl.focus();
                }
            }
        },

        // Initialize
        init: function () {

            function _removeOldAlerts() {
                const flashMessages = document.querySelector('#flash-messages');
                flashMessages.style.display = 'none';
                for (let i = 0; i < flashMessages.children.length; i++) {
                    const childItem = flashMessages.children[i];
                    if (childItem.tagName === 'NOSCRIPT' || childItem.id === 'alert-template') {
                        continue;
                    }
                    flashMessages.removeChild(childItem);
                }
            }

            //LOGIN-4372 LOGIN-2903 PORTAL-942
            //this way we can follow which button was originally clicked. works also in Safari
            ['mousemove', 'touchmove'].forEach(function(eventToListen) {
                window.addEventListener(eventToListen, function (eventCalled) {
                    if (eventCalled.target.tagName === 'INPUT' && eventCalled.target.closest('#implogin')) {
                        C.last_focus_field = eventCalled.target;
                    }
                });
            });

            const submitButtonSel = 'button[type=submit],input[type=submit]';
            document.querySelectorAll(submitButtonSel).forEach(function (el) {
                el.addEventListener('click', function () {
                    document.querySelectorAll(submitButtonSel).forEach(function (el2) {
                        el2.removeAttribute('clicked');
                    });
                    el.setAttribute('clicked', 'true');
                });
            });

            // event listeners for the login form
            // Check if we can set cookies
            if (! canSetCookie()) {
                _removeOldAlerts();
                inx.v6.showFlashMessage('warning', inx.i18n.cookies_required, false);
                document.querySelector(C.CN.LOGIN_BOTTOM_BLOCK).style.display = 'none';
            }
            const loginForm = document.querySelector(C.IDS.LFORM);
            if (!loginForm) {
                return;
            }
            loginForm.addEventListener('submit', function (e) {
                const
                    pw = document.querySelector(C.IDS.PASS),
                    un = document.querySelector(C.IDS.USER);

                const clickedElID = document.querySelector("[type=submit][clicked=true]").id;

                if (clickedElID !== 'biometric-btn' && (un.value === '' || pw.value === '')) {
                    _removeOldAlerts();
                    inx.v6.showFlashMessage('danger', inx.i18n.login_empty_fields, false);
                    document.querySelector(C.CN.LOGIN_BOTTOM_BLOCK).style.display = 'none';
                    if (un.value === '') {
                        un.focus();
                    } else {
                        pw.focus();
                    }
                    e.preventDefault();
                    return false;
                }
                else {
                    prepareSubmit(this);
                    //update CSRF protection page login form submit to make sure we have fresh CSRF token
                    csrf_protection();
                    return true;
                }
            });

            // // Set focus for Username (Ticket PORTAL-667: Input focus is now called with banner system function affixTop())
            ['focus', 'input'].forEach(function(eventToListen) {
                window.addEventListener(eventToListen, function (eventCalled) {
                    if (eventCalled.target.tagName === 'INPUT' && eventCalled.target.closest('#implogin')) {
                        C.last_focus_field = eventCalled.target;
                    }
                });
            });

            // // Toggle SSL
            // var ssl = document.querySelector(C.IDS.USESSL);
            // if (ssl) {
            //     ssl.click(function () {
            //         const frm = document.querySelector(C.IDS.LFORM);
            //         /^(http)(s)?(:.+)/.test(frm.getAttribute('action'));
            //         frm.setAttribute('action', RegExp.$1 + ((RegExp.$2 === 's')?'':'s') + RegExp.$3);
            //         });
            // }

            document.querySelector('.login-block .close').addEventListener('click', function () {
                _removeOldAlerts();
                const bottomBlock = document.querySelector(C.CN.LOGIN_BOTTOM_BLOCK);
                if (bottomBlock) {
                    bottomBlock.style.display = 'block';
                }
            });
        }
    };
})();
